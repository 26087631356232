import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../store/app/app";
import myicon from "./icon/logout (1).png"; // Import your desired icon
import styles from "./Header.module.css";

interface HeaderProps {
  handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleLogout }) => {
  const app = useSelector((state: any) => state.app.allData);
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(
      appActions.setAppData({
        userId: "",
        entityId: "",
        entityName: "",
        userName: "",
        email: "",
      })
    );
    handleLogout(); // Call the handleLogout function passed from Layout or wherever Header is used
  };

  return (
    <Navbar className={styles.nav} variant="dark" expand="lg">
      <Navbar.Brand href="/" className={styles.a}>
        TickTrackIT
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* This section can be empty or used for other links on the left */}
        </Nav>
        <Nav>
        <Link
            className={`${styles.link} nav-item nav-link active`}
            to="/FAQ"
          >
            FAQ
          </Link>
          {app && app.userId ? (
            <Link
              className={`${styles.link} nav-item nav-link active`}
              to="/"
              onClick={logout}
            >
              <img src={myicon} alt="Logout Icon" style={{ width: 30, height: 30, marginRight: '8px' }} />
            </Link>
          ) : (
            <Link
              className={`${styles.link} nav-item nav-link active`}
              to="/Login"
            >
              Login
            </Link>
          )}
          {/* Adding FAQ link */}
          
        </Nav>
        <span className={`mr-auto ${styles.wel}`}>
          Welcome{" "}
          {app && app.userName
            ? `${app.userName} - ${app.entityName}`
            : "Guest"}
        </span>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
